const DEFAULT_LOCALE = 'en';
const LANGUAGE_DETECTION_STORAGE_KEY = 'first-language-detection';
const LANGUAGE_NOTIFICATION_STORAGE_KEY = 'show-language-notification';
const browserLang = navigator.language.slice(0, 2);
const websiteLocale = document.documentElement.lang;
const headerNotification = document.getElementById('header-notification');

let availableLanguageUrl = null;
let defaultLocaleUrl = null;

const wasLanguageDetectedBefore = JSON.parse(
  localStorage.getItem(LANGUAGE_DETECTION_STORAGE_KEY)
);

const showLanguageNotification = JSON.parse(
  localStorage.getItem(LANGUAGE_NOTIFICATION_STORAGE_KEY)
);

const closeNotification = () => {
  headerNotification.classList.remove('header-notification--is-open');
  localStorage.setItem(LANGUAGE_NOTIFICATION_STORAGE_KEY, false);
};

document.addEventListener('DOMContentLoaded', () => {
  if (showLanguageNotification) {
    // show language notification because we couldn't match browser lang with any available site lang
    headerNotification.classList.add('header-notification--is-open');

    headerNotification.addEventListener('click', closeNotification);

    document
      .querySelectorAll('.language-switcher__link')
      .forEach(link => link.addEventListener('click', closeNotification));
  }

  if (browserLang !== websiteLocale && !wasLanguageDetectedBefore) {
    document.querySelectorAll('[data-lang]').forEach(element => {
      if (element.dataset.lang === browserLang) {
        availableLanguageUrl = element.getAttribute('href');
        return;
      }

      if (element.dataset.lang === DEFAULT_LOCALE) {
        defaultLocaleUrl = element.getAttribute('href');
      }
    });

    if (availableLanguageUrl) {
      location.pathname = availableLanguageUrl;
      return;
    }

    if (defaultLocaleUrl) {
      location.pathname = defaultLocaleUrl;
      localStorage.setItem(LANGUAGE_NOTIFICATION_STORAGE_KEY, true);
    }
  }
});

// After first ever page load, we set this property to allow user to navigate freely
localStorage.setItem(LANGUAGE_DETECTION_STORAGE_KEY, true);
