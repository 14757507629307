import modalData from '../../_data/modalsData';
import { modalTeaserClose } from './modalTeaser';

const lang = document.querySelector('html').getAttribute('lang');
const body = document.querySelector('body');
const modalContent = document.querySelector('#modal-content');
const modalContentOverlay = modalContent.querySelector(
  '#modal-content-overlay'
);
const modalContentMedia = modalContent.querySelector('#modal-content-media');
const modalContentText = modalContent.querySelector('#modal-content-text');
const modalContentTextCol1 = modalContent.querySelector(
  '#modal-content-text-col-1'
);
const modalContentTextCol2 = modalContent.querySelector(
  '#modal-content-text-col-2'
);
// eslint-disable-next-line prefer-const
const openModalContentBtn = document.querySelectorAll(
  '[data-modal-type=content]'
);
const closeModalContentBtn = modalContent.querySelector(
  '#close-modal-content-btn'
);

// eslint-disable-next-line prefer-const
let openModalContentBtnFromModalTeaser = null;

let isVideoPlaying = false;
let videoPlayer = null;
let videoPlayBtn = null;

let isModalContentOpen = false;

const keyboardHandler = event => {
  if (event.key === 'Escape') {
    // eslint-disable-next-line no-use-before-define
    modalContentClose();
  }
};

const videoPlay = () => {
  videoPlayer.play();
  videoPlayBtn.classList.add('modal-content-media__play-btn--is-hidden');
  isVideoPlaying = true;
};

const videoStop = () => {
  videoPlayer.pause();
  videoPlayBtn.classList.remove('modal-content-media__play-btn--is-hidden');
  isVideoPlaying = false;
};

const videoHandler = () => {
  if (!isVideoPlaying) {
    videoPlay();
  } else {
    videoStop();
  }
};

const videoElementsHandler = () => {
  videoPlayer = modalContent.querySelector('#modal-content-video-player');
  videoPlayBtn = modalContent.querySelector('#modal-content-video-play-btn');

  videoPlayer.addEventListener('click', videoHandler);
};

const modalContentMediaBuilder = (media = {}) => {
  if (media?.type === 'image') {
    return `
    <img class="modal-content-media__image" src="/assets/images/${media?.src}" alt="${media?.alt}">
    `;
  }

  if (media?.type === 'video') {
    return `
      <video
        id="modal-content-video-player"
        class="modal-content-media__video"
        src="https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"
        poster="https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217"
        preload="auto"
      >
      </video>

      <button
        id="modal-content-video-play-btn"
        class="modal-content-media__play-btn"
      >
      <svg width="187" height="213" viewBox="0 0 187 213" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.25 4.83398V208.167" stroke="white" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M182.167 106.5L4.25 208.167" stroke="white" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M182.167 106.501L4.25 4.83398" stroke="white" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      </button>
    `;
  }
};

const modalContentTextCol1Builder = (content = {}) =>
  `<h2 class="heading heading--h1">${content?.text}</h2>`;

const modalContentTextCol2Builder = (content = {}) => {
  if (content?.type === 'team') {
    if (lang === 'de') {
      return `
      <p class="copy copy--regular contact-box__copy">${content?.roleDe}</p>
      <p class="copy copy--regular">
        <!--<a class="link link--primary link--has-underline" href="${content?.phoneLink}">${content?.phone}</a
        ><br />-->
        <a class="link link--primary link--has-underline" href="mailto:${content?.email}">${content?.email}</a
        >
      </p>
    `;
    } else {
      return `
      <p class="copy copy--regular contact-box__copy">${content?.roleEn}</p>
      <p class="copy copy--regular">
        <a class="link link--primary link--has-underline" href="${content?.phoneLink}">${content?.phone}</a
        ><br />
        <a class="link link--primary link--has-underline" href="mailto:${content?.email}">${content?.email}</a
        >
      </p>
    `;
    }
  }

  return `
    <p class="copy copy--regular">${content?.text}</p>
  `;
};

const modalContentClose = () => {
  if (isModalContentOpen) {
    isModalContentOpen = false;
    modalContent.classList.remove('modal-content--is-open');
    body.classList.remove('scroll-lock');

    document.removeEventListener('keydown', keyboardHandler);

    if (videoPlayer) {
      videoStop();
      videoPlayer.removeEventListener('click', videoHandler);
    }

    if (openModalContentBtnFromModalTeaser) {
      openModalContentBtnFromModalTeaser.removeEventListener(
        'click',
        // eslint-disable-next-line no-use-before-define
        modalContentHandler
      );

      openModalContentBtnFromModalTeaser = null;
    }
  }
};

export const modalContentOpen = () => {
  if (!isModalContentOpen) {
    isModalContentOpen = true;
    modalContent.classList.add('modal-content--is-open');
    body.classList.add('scroll-lock');

    document.addEventListener('keydown', keyboardHandler);

    setTimeout(() => {
      modalTeaserClose('modal-teaser');
    }, 500);
  }
};

const modalContentHandler = event => {
  const id =
    event.target.dataset.modalData ||
    event.target.closest('#modal-teaser-action-btn').dataset.modalData;

  modalContentMedia.innerHTML = modalContentMediaBuilder(
    modalData[id]?.content?.media
  );

  if (modalData[id]?.content?.media?.type === 'video') {
    videoElementsHandler();
  }

  // Set href for contact overlay
  modalContent.setAttribute('href', modalData[id]?.url);

  modalContentTextCol1.innerHTML = modalContentTextCol1Builder(
    modalData[id]?.content?.text?.col1
  );

  modalContentTextCol2.innerHTML = modalContentTextCol2Builder(
    modalData[id]?.content?.text?.col2
  );

  if (modalData[id]?.content?.text?.col2?.type === 'team') {
    modalContentText.classList.add('modal-content-text--col2-small');
  } else if (
    modalContentText.classList.contains('modal-content-text--col2-small')
  ) {
    modalContentText.classList.remove('modal-content-text--col2-small');
  }

  modalContentOpen('modal-content', event);
};

openModalContentBtn.forEach(btn => {
  btn.addEventListener('click', modalContentHandler);
});

closeModalContentBtn.addEventListener('click', event => {
  if (event.currentTarget === closeModalContentBtn) {
    modalContentClose('modal-content');
  }
});

modalContentOverlay.addEventListener('click', event => {
  if (event.target === modalContentOverlay) {
    modalContentClose('modal-content');
  }
});

export const assignOpenModalContentBtn = element => {
  openModalContentBtnFromModalTeaser = element;
  openModalContentBtnFromModalTeaser.addEventListener(
    'click',
    modalContentHandler
  );
};
