import { breakpoints, breakpointNames } from './utils/breakpoints';

const SELECTORS = {
  ROOT: '.js-video-player'
};

class VideoPlayer {
  static initAll() {
    document.querySelectorAll(SELECTORS.ROOT).forEach(element => {
      new VideoPlayer(element);
    });
  }

  constructor(element) {
    this.videoPlayerWrapper = element;
    this.videoPlayer = element.querySelector('video');

    this.videoPlayerSources = this.videoPlayer.querySelectorAll('source');
    this.currentBreakpoint = null;

    this.VIDEO_FILE = this.videoPlayer.dataset.filename;
    this.VIDEO_FILE_LOCATION = this.videoPlayer.dataset.filepath;

    console.log('new video player', this);

    this.videoSources = {
      [breakpointNames.SMALL]: `${this.VIDEO_FILE}-360`,
      [breakpointNames.MEDIUM_MIN]: `${this.VIDEO_FILE}-480`,
      [breakpointNames.MEDIUM_MAX]: `${this.VIDEO_FILE}-720`,
      [breakpointNames.LARGE]: `${this.VIDEO_FILE}-1080`
    };

    this.initialize();
  }

  isSafariBrowser() {
    return (
      navigator.userAgent.indexOf('Safari') > -1 &&
      navigator.userAgent.indexOf('Chrome') <= -1
    );
  }

  updateVideoElement(currentBreakpoint) {
    const videoSource = this.videoSources[currentBreakpoint];

    this.videoPlayerSources.forEach(source => {
      if (source.type === 'video/webm') {
        source.src = `${this.VIDEO_FILE_LOCATION}/${videoSource}.webm`;
      }

      if (source.type === 'video/mp4') {
        source.src = `${this.VIDEO_FILE_LOCATION}/${videoSource}.mp4`;
      }
    });

    this.videoPlayerWrapper.style.backgroundImage = `url(${this.VIDEO_FILE_LOCATION}/${videoSource}.jpg)`;
    this.videoPlayer.load();

    // Due to a open bug on Safari with object-fit cover not being aplied initially to some elements
    // we need to delay the presentation of the video until the size calculations are done by Safari.
    // Happens both on mobile and desktop in mobile viewports.
    // https://developer.apple.com/forums/thread/707247
    // https://developer.apple.com/forums/thread/709099
    if (!this.isSafariBrowser()) {
      this.videoPlayer.style.opacity = 1;
      this.videoPlayer.play();
    } else {
      this.videoPlayer.addEventListener('loadeddata', () => {
        setTimeout(() => {
          this.videoPlayer.style.opacity = 1;
          this.videoPlayer.play();
        }, 500);
      });
    }
  }

  breakpointResolver(pageWidth) {
    if (pageWidth <= breakpoints.small) {
      return breakpointNames.SMALL;
    }

    if (pageWidth <= breakpoints.mediumMin) {
      return breakpointNames.MEDIUM_MIN;
    }

    if (pageWidth <= breakpoints.mediumMax) {
      return breakpointNames.MEDIUM_MAX;
    }

    return breakpointNames.LARGE;
  }

  onWindowResize() {
    const pageWidth = window.innerWidth;
    const newBreakpoint = this.breakpointResolver(pageWidth);

    if (newBreakpoint !== this.currentBreakpoint) {
      this.currentBreakpoint = newBreakpoint;
      this.updateVideoElement(newBreakpoint);
    }
  }

  initialize() {
    window.addEventListener('resize', () => this.onWindowResize());

    this.onWindowResize();
  }
}

export default VideoPlayer;
