import { isFuture, isPast } from 'date-fns';
import { breakpoints } from './utils/breakpoints';

const baseUrl =
  'https://mlne9y99.api.sanity.io/v2021-10-21/data/query/production';
const notificationQueryString =
  window.lingner?.buildEnv === 'production'
    ? 'query=*[ _type == "notification"%26%26!(_id in path("drafts.**"))%26%26isActiveProduction]'
    : 'query=*[ _type == "notification"%26%26!(_id in path("drafts.**"))%26%26isActiveStaging]';
const notificationWrapper = document.getElementById('notification-wrapper');
const notificationIntersectionTarget = document.getElementById(
  'notification-intersection-target'
);
let wasNotificationShown = false;
let wasNotificationInitialized = false;

const getActiveNotification = notifications => {
  const activeNotification = notifications.find(notification =>
    window.lingner.buildEnv === 'production'
      ? notification.isActiveProduction
      : notification.isActiveStaging
  );

  if (!activeNotification) {
    return null;
  }

  const isStartDateValid = isPast(new Date(activeNotification.startDate));
  const isEndDateValid = isFuture(new Date(activeNotification.endDate));

  if (!isStartDateValid || !isEndDateValid) {
    return null;
  }

  return activeNotification;
};

const getNotification = async () => {
  try {
    const response = await fetch(`${baseUrl}?${notificationQueryString}`);

    if (!response.ok) {
      throw new Error('Network response was not OK');
    }

    const data = await response.json();

    return data.result;
  } catch (error) {
    console.error('Error getting notification', error);
  }
};

const renderNotification = notification => {
  const lang = window.lingner.lang || document.documentElement.lang || 'de';
  const notificationLabel = notificationWrapper.querySelector(
    '#notification-label'
  );
  const notificationText =
    notificationWrapper.querySelector('#notification-text');
  const notificationLink =
    notificationWrapper.querySelector('#notification-link');

  if (!notificationWrapper) {
    return;
  }

  if (lang === 'de') {
    notificationLabel.textContent = notification.label;
    notificationText.textContent = notification.text;
    notificationLink.textContent = notification.linkText;
    notificationLink.href = notification.link;
    notificationLink.target = notification.linkTarget;
  }

  if (notification.isTranslated && lang !== 'de') {
    notificationLabel.textContent = notification.label_en;
    notificationText.textContent = notification.text_en;
    notificationLink.textContent = notification.linkText_en;
    notificationLink.href = notification.link_en;
    notificationLink.target = notification.linkTarget;
  }

  notificationWrapper.classList.add('notification-wrapper--is-visible');
};

const initializeNotification = async () => {
  if (
    !wasNotificationInitialized &&
    window.innerWidth >= breakpoints.large &&
    notificationWrapper &&
    notificationIntersectionTarget
  ) {
    wasNotificationInitialized = true;
    window.removeEventListener('resize', initializeNotification);

    try {
      const notifications = await getNotification();
      // eslint-disable-next-line no-use-before-define
      setupNotification(notifications);
    } catch (error) {
      console.error('Error initializing notification', error);
    }
  }
};

const onWindowScroll = () => {
  if (
    notificationWrapper.getBoundingClientRect().bottom + 20 >=
    notificationIntersectionTarget.getBoundingClientRect().top
  ) {
    notificationWrapper.classList.remove('notification-wrapper--is-visible');
    window.removeEventListener('scroll', () => onWindowScroll());
  }

  if (!wasNotificationShown && window.scrollY < 50) {
    notificationWrapper.classList.add('notification-wrapper--is-visible');
    wasNotificationShown = true;
  }
};

const setupNotification = notifications => {
  if (!Array.isArray(notifications) || !notifications.length) {
    return;
  }

  const activeNotification = getActiveNotification(notifications);

  if (!activeNotification) {
    return;
  }

  window.addEventListener('scroll', () => onWindowScroll());

  renderNotification(activeNotification);
  onWindowScroll();
};

window.addEventListener('resize', initializeNotification);
initializeNotification();
