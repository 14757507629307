const team = require('./team');

module.exports = {
  stefanLingner: {
    _id: 'stefanLingner',
    url: '?contact=stefanLingner',
    content: {
      media: {
        alt: 'Stefan Lingner',
        type: 'image',
        src: 'home/partner/stefan-lingner.jpg'
      },
      text: {
        col1: {
          text: team['stefanLingner'].name,
          type: 'text'
        },
        col2: {
          roleDe: team['stefanLingner'].roleDe,
          roleEn: team['stefanLingner'].roleEn,
          phone: team['stefanLingner'].phone,
          phoneLink: team['stefanLingner'].phoneLink,
          email: team['stefanLingner'].email,
          type: 'team'
        }
      }
    }
  },
  tobiasHurrle: {
    _id: 'tobiasHurrle',
    url: '?contact=tobiasHurrle',
    content: {
      media: {
        alt: 'Tobias Hurrle',
        type: 'image',
        src: 'home/partner/tobias-hurrle.jpg'
      },
      text: {
        col1: {
          text: team['tobiasHurrle'].name,
          type: 'text'
        },
        col2: {
          roleDe: team['tobiasHurrle'].roleDe,
          roleEn: team['tobiasHurrle'].roleEn,
          phone: team['tobiasHurrle'].phone,
          phoneLink: team['tobiasHurrle'].phoneLink,
          email: team['tobiasHurrle'].email,
          type: 'team'
        }
      }
    }
  },
  fabianBorst: {
    _id: 'fabianBorst',
    url: '?contact=fabianBorst',
    content: {
      media: {
        alt: 'Fabian Borst',
        type: 'image',
        src: 'home/partner/fabian-borst.jpg'
      },
      text: {
        col1: {
          text: team['fabianBorst'].name,
          type: 'text'
        },
        col2: {
          roleDe: team['fabianBorst'].roleDe,
          roleEn: team['fabianBorst'].roleEn,
          phone: team['fabianBorst'].phone,
          phoneLink: team['fabianBorst'].phoneLink,
          email: team['fabianBorst'].email,
          type: 'team'
        }
      }
    }
  },
  christianLaurin: {
    _id: 'christianLaurin',
    url: '?contact=christianLaurin',
    content: {
      media: {
        alt: 'Christian Laurin',
        type: 'image',
        src: 'home/partner/christian-laurin.jpg'
      },
      text: {
        col1: {
          text: team['christianLaurin'].name,
          type: 'text'
        },
        col2: {
          roleDe: team['christianLaurin'].roleDe,
          roleEn: team['christianLaurin'].roleEn,
          phone: team['christianLaurin'].phone,
          phoneLink: team['christianLaurin'].phoneLink,
          email: team['christianLaurin'].email,
          type: 'team'
        }
      }
    }
  },
  wojtekMisiewicz: {
    _id: 'wojtekMisiewicz',
    url: '?contact=wojtekMisiewicz',
    content: {
      media: {
        alt: 'Wojtek Misiewicz',
        type: 'image',
        src: 'home/partner/wojtek-misiewicz.jpg'
      },
      text: {
        col1: {
          text: team['wojtekMisiewicz'].name,
          type: 'text'
        },
        col2: {
          roleDe: team['wojtekMisiewicz'].roleDe,
          roleEn: team['wojtekMisiewicz'].roleEn,
          phone: team['wojtekMisiewicz'].phone,
          phoneLink: team['wojtekMisiewicz'].phoneLink,
          email: team['wojtekMisiewicz'].email,
          type: 'team'
        }
      }
    }
  },
  danielNill: {
    _id: 'danielNill',
    url: '?contact=danielNill',
    content: {
      media: {
        alt: 'Daniel Nill',
        type: 'image',
        src: 'home/partner/daniel-nill.jpg'
      },
      text: {
        col1: {
          text: team['danielNill'].name,
          type: 'text'
        },
        col2: {
          roleDe: team['danielNill'].roleDe,
          roleEn: team['danielNill'].roleEn,
          phone: team['danielNill'].phone,
          phoneLink: team['danielNill'].phoneLink,
          email: team['danielNill'].email,
          type: 'team'
        }
      }
    }
  },
  vivamus: {
    _id: 'vivamus',
    teaser: {
      action: {
        type: 'modalContent',
        _id: 'vivamus'
      },
      media: {
        alt: '',
        type: 'image',
        src: 'modal-teaser-vivamus-01-desktop.jpg'
      },
      text: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
      type: 'wide'
    },
    content: {
      media: {
        alt: '',
        type: 'image',
        src: 'modal-content-vivamus-01-desktop.jpg'
      },
      text: {
        col1: {
          text: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
          type: 'text'
        },
        col2: {
          text: 'Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Aenean lacinia bibendum nulla sed consectetur. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean lacinia bibendum nulla sed consectetur. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          type: 'text'
        }
      }
    }
  }
};
