import './examplePagesScrollEffect';
import './languageDetection';
import './header';
import 'jquery';
import './modalContent';
import './modalTeaser';
import './newsArticle';
import './notification';
import './podularTeaser';
import './scrollIntoView';
import './swiper';
import './stickyNavigation';
import VideoPlayer from './videoPlayer';
import modalData from '../../_data/modalsData';

VideoPlayer.initAll();

// Check url parameter for contact overlay + open
const searchParams = new URLSearchParams(window.location.search);
if (searchParams.has('contact')) {
  const contactName = searchParams.get('contact');
  const contact = document.getElementById(contactName);

  if (contact) {
    contact.click();
  }
}
