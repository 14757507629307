document.querySelectorAll('.podular-teaser__copy-item').forEach(item => {
  item.addEventListener('click', event => {
    document
      .querySelector('.podular-teaser__copy-item--is-active')
      .classList.remove('podular-teaser__copy-item--is-active');

    event.currentTarget.classList.add('podular-teaser__copy-item--is-active');

    // event.currentTarget.scrollIntoView({ scrollIntoView: 'smooth' });
  });
});
