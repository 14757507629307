const lang = require('../translations/translations')

module.exports = {
  stefanLingner: {
    _id: 'stefanLingner',
    email: 's.lingner@lingner.com',
    image: 'team/stefan-lingner.jpg',
    name: 'Stefan Lingner',
    phone: '+49 151 174 747 61',
    phoneLink: 'tel:+4915117474761',
    roleDe: lang.de.team.stefanLingner.role,
    roleEn: lang.en.team.stefanLingner.role
  },
  tobiasHurrle: {
    _id: 'tobiasHurrle',
    email: 't.hurrle@lingner.com',
    image: 'team/tobias-hurrle.jpg',
    name: 'Tobias Hurrle',
    phone: '+49 170 856 06 95',
    phoneLink: 'tel:+491708560695',
    roleDe: lang.de.team.tobiasHurrle.role,
    roleEn: lang.en.team.tobiasHurrle.role
  },
  fabianBorst: {
    _id: 'fabianBorst',
    email: 'f.borst@lingner.com',
    image: 'team/fabian-borst.jpg',
    name: 'Fabian Borst',
    phone: '+49 178 233 07 98',
    phoneLink: 'tel:+491782330798',
    roleDe: lang.de.team.fabianBorst.role,
    roleEn: lang.en.team.fabianBorst.role
  },
  christianLaurin: {
    _id: 'christianLaurin',
    email: 'c.laurin@lingner.com',
    image: 'team/christian-laurin.jpg',
    name: 'Christian Laurin',
    phone: '+49 173 321 71 71',
    phoneLink: 'tel:+491733217171',
    roleDe: lang.de.team.christianLaurin.role,
    roleEn: lang.en.team.christianLaurin.role
  },
  wojtekMisiewicz: {
    _id: 'wojtekMisiewicz',
    email: 'w.misiewicz@lingner.com',
    image: 'team/wojtek-misiewicz.jpg',
    name: 'Wojtek Misiewicz',
    phone: '+49 171 216 30 35',
    phoneLink: 'tel:+491712163035',
    roleDe: lang.de.team.wojtekMisiewicz.role,
    roleEn: lang.en.team.wojtekMisiewicz.role
  },
  danielNill: {
    _id: 'danielNill',
    email: 'd.nill@lingner.com',
    image: 'team/daniel-nill.jpg',
    name: 'Daniel Nill',
    phone: '+49 160 986 796 61',
    phoneLink: 'tel:+4916098679661',
    roleDe: lang.de.team.danielNill.role,
    roleEn: lang.en.team.danielNill.role
  },
  carolaRogge: {
    _id: 'carolaRogge',
    email: 'c.rogge@lingner.com',
    image: 'team/carola-rogge.jpg',
    name: 'Carola Rogge',
    phone: '+49 7131 - 642 90 47',
    phoneLink: 'tel:+4971316429047'
  }
};
