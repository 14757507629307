import { throttle } from './utils/performance';

const body = document.querySelector('body');
const header = document.querySelector('#header');
const menu = document.querySelector('#navigation-menu');
const scrollDownClass = 'header--scroll-down';
const scrollUpClass = 'header--scroll-up';

let lastScroll = 0;

// menu
document.querySelectorAll('.header-menu-btn').forEach(btn => {
  btn.addEventListener('click', () => {
    if (menu.classList.contains('navigation-menu--is-open')) {
      menu.classList.remove('navigation-menu--is-open');
      header.classList.remove('header--is-open', 'bkg-primary');
      body.classList.remove('scroll-lock');

      document.querySelector('#navigation-menu').scrollTop = 0;
    } else {
      menu.classList.add('navigation-menu--is-open');
      header.classList.add('header--is-open', 'bkg-primary');
      body.classList.add('scroll-lock');
    }
  });
});

// scroll behaviour
const onScroll = () => {
  const currentScroll = window.pageYOffset;

  if (currentScroll <= 0) {
    header.classList.remove(scrollUpClass);
    return;
  }

  if (
    currentScroll > lastScroll &&
    !header.classList.contains(scrollDownClass)
  ) {
    // down
    header.classList.remove(scrollUpClass);
    header.classList.add(scrollDownClass);
  } else if (
    currentScroll < lastScroll &&
    header.classList.contains(scrollDownClass)
  ) {
    // up
    header.classList.remove(scrollDownClass);
    header.classList.add(scrollUpClass);
  }

  lastScroll = currentScroll;
};

window.addEventListener('scroll', () => {
  throttle(onScroll, 20);
});

onScroll();
