// scroll / jump to element function
const target = document.querySelectorAll('.scroll-to');
const headerMenuClose = document.querySelector('.header-menu--opened button');

for (const item of target) {
  item.addEventListener('click', function (e) {
    const elementID = item.getAttribute('id').replace('scroll-to-', '');
    const element = document.getElementById(elementID);
    if (element != null) {
      e.stopPropagation();
      e.preventDefault();
      setTimeout(function () {
        element.scrollIntoView({
          //behaviour: 'auto',
          //block: 'nearest',
          //inline: 'center'
        });
      }, 100);
      const elementHref = item.getAttribute('href');
      history.pushState(null, null, elementHref);
      headerMenuClose.click(e);
    }
  });
}
