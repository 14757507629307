/*
 * Career Slider (14 Reasons for Lingner)
 * Two synced Sliders (Img + Text)
 */
import Swiper from 'swiper/bundle';

// init Swiper
if (typeof Swiper !== 'undefined') {
  const sliderNormal = new Swiper('.slider--normal', {
    grabCursor: true,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });

  const textSlider = new Swiper('.slider--career .slider-text', {
    grabCursor: true,
    loop: true,
    effect: 'fade'
  });

  const imgSlider = new Swiper('.slider--career .slider-img > .swiper', {
    grabCursor: true,
    loop: true,
    speed: 800,
    effect: 'slide',
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true
    },
    breakpoints: {
      1024: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  });

  if (document.getElementById('und-warum-lingner') !== null) {
    imgSlider.controller.control = textSlider;
    textSlider.controller.control = imgSlider;
  }
}
