const isExampleCasePage = !!document.querySelector('.example-case-page');

function handleActiveElementsPosition() {
  const activeElements = document.querySelectorAll('.scroll-tween--is-active');

  activeElements.length &&
    activeElements.forEach(element => {
      element.style.top = `${
        (element.getBoundingClientRect().bottom.toFixed(0) -
          element.getBoundingClientRect().height.toFixed(0) / 2) /
        10
      }px`;
    });
}

function addObserver(el) {
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('scroll-tween--is-active');
        handleActiveElementsPosition();
      } else {
        entry.target.classList.remove('scroll-tween--is-active');
      }
    });
  });

  observer.observe(el);
}

function scrollTrigger(selector) {
  const elements = document.querySelectorAll(selector);

  elements.forEach(element => {
    addObserver(element);
  });
}

if (isExampleCasePage) {
  window.addEventListener('scroll', handleActiveElementsPosition);
  scrollTrigger('.scroll-tween');
  handleActiveElementsPosition();
}
