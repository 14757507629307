const backButtonWrappers = document.querySelectorAll(
  '.article-back-button-wrapper'
);

class ArticleBackButton {
  constructor() {
    this.backButtons = document.querySelectorAll('.article-back-button');

    this.setButtonsVisibility();
  }

  isPreviousNavigationFromNewsPage() {
    return (
      document.referrer !== window.location.href &&
      document.referrer.includes(`${location.origin}/news`)
    );
  }

  setButtonsVisibility() {
    this.backButtons.forEach(button => {
      const action = button.dataset.nav || 'news';

      if (this.isPreviousNavigationFromNewsPage() && action === 'news') {
        button.classList.remove('article-back-button--is-hidden');
      } else if (
        !this.isPreviousNavigationFromNewsPage() &&
        action === 'home'
      ) {
        button.classList.remove('article-back-button--is-hidden');
      }
    });
  }
}

if (backButtonWrappers.length) {
  new ArticleBackButton();
}
