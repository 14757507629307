import { breakpoints } from './utils/breakpoints';

const BOTTOM_GAP = 25;
const SECTION_DISTANCE_TO_TOP = 100;
const SECTION_TOP_GAP = 8;
const stickyNavigationElement = document.querySelector('#sticky-navigation');

class StickyNavigation {
  constructor(element) {
    this.element = element;
    this.nav = element.querySelector('.sticky-navigation');
    this.root = element.querySelector('.sticky-navigation-wrapper');
    this.navItems = this.nav.querySelectorAll('.sticky-navigation-item');
    this.bottomPole = element.querySelector('.sticky-navigation-bottom-pole');
    this.initialize();
  }

  onWindowScroll() {
    const pageWidth = window.innerWidth;
    const navDistanceToTop =
      pageWidth <= breakpoints.mediumMin ? 0 : SECTION_DISTANCE_TO_TOP;
    const scrollPos = window.scrollY;
    const shouldBeAtBottom =
      Math.abs(this.nav.getBoundingClientRect().bottom + BOTTOM_GAP) >
      this.bottomPole.getBoundingClientRect().top;

    if (this.element.getBoundingClientRect().top < navDistanceToTop) {
      this.root.classList.add('sticky-navigation-wrapper--floating');
      this.root.style.top = `${navDistanceToTop}px`;

      this.navItems.forEach((link, index) => {
        const section = document.querySelector(link.hash);
        const sectionHeight = section.parentElement.offsetHeight;
        const sectionTop =
          section.getBoundingClientRect().top +
          window.scrollY -
          SECTION_TOP_GAP;

        if (
          scrollPos >= sectionTop &&
          scrollPos <= sectionTop + sectionHeight
        ) {
          this.setActiveItem(index, pageWidth);
        } else {
          this.removeActiveItem(index);
        }
      });
    } else {
      this.setActiveItem(0, pageWidth);
      this.root.classList.remove('sticky-navigation-wrapper--floating');
    }

    if (shouldBeAtBottom) {
      this.root.classList.add('sticky-navigation-wrapper--hidden');
    } else {
      this.root.classList.remove('sticky-navigation-wrapper--hidden');
    }
  }

  setActiveItem(index, pageWidth) {
    // In this method we receive a navigation item index, and set it's active classes
    if (
      !this.navItems[index].classList.contains('sticky-navigation-item--active')
    ) {
      this.navItems[index].classList.add('sticky-navigation-item--active');
      this.navItems[index]
        .querySelector('.sticky-navigation-element')
        .classList.add('text-color-secondary');
    }

    if (pageWidth && pageWidth < breakpoints.mediumMin) {
      if (index === 0) {
        // If is first item, we just scroll to the beggining
        this.nav.scrollLeft = 0;
      } else {
        const navWidth = this.nav.offsetWidth;
        const navItemPosition = this.navItems[index].offsetLeft;
        const navItemWidth = this.navItems[index].offsetWidth;

        if (navWidth < navItemPosition + navItemWidth) {
          this.nav.scrollLeft = navItemPosition;
        }
      }
    }
  }

  removeActiveItem(index) {
    // In this method we receive a navigation item index, and unset it's active classes
    this.navItems[index].classList.remove('sticky-navigation-item--active');
    this.navItems[index]
      .querySelector('.sticky-navigation-element')
      .classList.remove('text-color-secondary');
  }

  initialize() {
    // We initialize the stickyNavigation and add the main scroll listener
    window.addEventListener('scroll', () => this.onWindowScroll());

    this.navItems.forEach(link => {
      link.addEventListener('click', event => {
        event.preventDefault();

        const section = document.querySelector(link.hash);
        const sectionTop = section.getBoundingClientRect().top + window.scrollY;

        window.scroll({
          top: sectionTop || 0,
          left: 0,
          behavior: 'smooth'
        });
      });
    });

    this.onWindowScroll();
  }
}

if (stickyNavigationElement) {
  new StickyNavigation(stickyNavigationElement);
}
