import modalData from '../../_data/modalsData';
import { breakpoints } from './utils/breakpoints';
import { assignOpenModalContentBtn } from './modalContent';

const modalTeaser = document.querySelector('#modal-teaser');
const modalTeaserBody = modalTeaser.querySelector('#modal-teaser-body');
const modalTeaserText = modalTeaser.querySelector('#modal-teaser-text');
const modalTeaserSpaceGuard = 50;
const openModalTeaserBtn = document.querySelectorAll(
  '[data-modal-type=teaser]'
);
const modalTeaserActionBtn = document.querySelector('#modal-teaser-action-btn');

let isModalTeaserOpen = false;
let activeModalTeaserId = null;
let activeModalTeaserEvent = null;

const keyboardHandler = event => {
  if (event.key === 'Escape') {
    // eslint-disable-next-line no-use-before-define
    modalTeaserClose();
  }
};

const navigate = () => {
  window.location.href = modalData[activeModalTeaserId]?.teaser?.action?.url;
};

export const modalTeaserClose = () => {
  if (isModalTeaserOpen) {
    activeModalTeaserId = null;
    isModalTeaserOpen = false;
    activeModalTeaserEvent = null;
    modalTeaser.classList.remove('modal-teaser--is-open');

    document.removeEventListener('keydown', keyboardHandler);
  }
};

const modalTeaserOpen = (id, event) => {
  if (!isModalTeaserOpen) {
    activeModalTeaserId = id;
    isModalTeaserOpen = true;
    activeModalTeaserEvent = event;
    modalTeaser.classList.add('modal-teaser--is-open');

    document.addEventListener('keydown', keyboardHandler);
  }
};

const modalTeaserSizeCalculator = event => {
  const buttonBounds = event.target.getBoundingClientRect();
  const buttonX = buttonBounds.x;
  const buttonY = buttonBounds.y;

  const pageWidth = window.innerWidth;
  const pageHeight = window.innerHeight;

  const modalWidth = parseInt(window.getComputedStyle(modalTeaser).width, 10);
  const modalHeight = parseInt(window.getComputedStyle(modalTeaser).height, 10);

  if (pageWidth <= modalWidth || pageHeight <= modalHeight) {
    modalTeaser.style.margin = 'auto';
  }

  if (pageWidth <= breakpoints.mediumMin) {
    modalTeaser.style.right = 0;
    modalTeaser.style.left = 0;
    modalTeaser.style.maxWidth = '85%';
  } else {
    modalTeaser.style.right = 'auto';
    modalTeaser.style.maxWidth = 'none';

    if (buttonX < modalWidth + modalTeaserSpaceGuard) {
      modalTeaser.style.left = `${modalTeaserSpaceGuard}px`;
    } else {
      modalTeaser.style.left = `${
        buttonX - modalWidth - modalTeaserSpaceGuard
      }px`;
    }
  }

  if (pageHeight <= breakpoints.mediumMax) {
    modalTeaser.style.top = 0;
    modalTeaser.style.bottom = 0;
    modalTeaser.style.maxHeight = '85%';
  } else {
    modalTeaser.style.bottom = 'auto';
    modalTeaser.style.maxHeight = 'none';

    if (buttonY < modalHeight + modalTeaserSpaceGuard) {
      modalTeaser.style.top = `${modalTeaserSpaceGuard}px`;
    } else if (buttonY + modalHeight > pageHeight) {
      modalTeaser.style.top = `${
        pageHeight - modalHeight - modalTeaserSpaceGuard
      }px`;
    } else {
      modalTeaser.style.top = `${buttonY - modalHeight / 2}px`;
    }
  }
};

const modalTeaserHandler = event => {
  const id = event.target.dataset.modalData;

  if (isModalTeaserOpen && activeModalTeaserId === id) {
    modalTeaserClose();
    return;
  }

  if (modalData[id]?.teaser?.type === 'wide') {
    modalTeaser.classList.add('modal-teaser__body--wide');
  } else if (modalTeaser.classList.contains('modal-teaser__body--wide')) {
    modalTeaser.classList.remove('modal-teaser__body--wide');
  }

  const image = `/assets/images/${modalData[id].teaser.media.src}`;
  const text = modalData[id].teaser.text;

  modalTeaserBody.style.backgroundImage = `url(${image})`;
  modalTeaserText.textContent = text;

  if (modalData[id]?.teaser?.action?.type === 'modalContent') {
    modalTeaserActionBtn.removeEventListener('click', navigate);

    modalTeaserActionBtn.dataset.modalType = 'content';
    modalTeaserActionBtn.dataset.modalData = id;

    assignOpenModalContentBtn(modalTeaserActionBtn);
  } else if (modalData[id]?.teaser?.action?.type === 'link') {
    if (modalTeaserActionBtn.dataset.modalData) {
      delete modalTeaserActionBtn.dataset.modalData;
    }

    modalTeaserActionBtn.addEventListener('click', navigate);
  }

  modalTeaserSizeCalculator(event);

  modalTeaserOpen(id, event);
};

modalTeaser.addEventListener('click', () => {
  modalTeaserClose();
});

openModalTeaserBtn.forEach(btn => {
  btn.addEventListener('click', event => modalTeaserHandler(event));
});

window.addEventListener('resize', () => {
  if (isModalTeaserOpen) {
    modalTeaserSizeCalculator(activeModalTeaserEvent);
  }
});
